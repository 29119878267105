import startPageAdapter from 'backend/lib/adapters/startPageAdapter'
import edithService from 'backend/services/v1/edithService'
import EdithContainer from 'components/EdithContainer/EdithContainer'
import HiddenHeading from 'components/HiddenHeading/HiddenHeading'
import Layout from 'components/Layout/Layout'
import OgMetadata from 'components/OgMetadata/OgMetadata'
import { DataLayerPageType } from 'frontend/enums'
import { CategoryItem, StartPage } from 'frontend/types'
import usePageTracking from 'frontend/utils/hooks/usePageTracking'
import { GetStaticProps } from 'next'
import Head from 'next/head'
import { getAdaptedCategories } from 'utils/getAdaptedCategories'

interface Props {
  startPage: StartPage
  categories: CategoryItem[]
}

export const MainPage = ({ categories, startPage }: Props) => {
  usePageTracking(DataLayerPageType.START_PAGE)

  const description =
    'UR Play är en streamingtjänst från UR som erbjuder underhållande och lärorika barnprogram, poddar, dokumentärer, kortfilmer och föreläsningar.'
  return (
    <>
      <Head>
        <title>UR Play</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://urplay.se" />
      </Head>
      <OgMetadata
        title="UR Play | Dina favoritprogram när och var du vill."
        description={description}
        sharePath={'/'}
      />
      <Layout categories={categories}>
        <HiddenHeading text="UR Play" />
        <EdithContainer blocks={startPage.blocks} />
      </Layout>
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const startPage = startPageAdapter.adapt(await edithService.getStartPage())

  const categories = await getAdaptedCategories()

  return {
    props: { startPage, categories },
    revalidate: 900, // seconds
  }
}

export default MainPage
